import styled from "styled-components";
import { device } from "../../utils/device";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 35%;
    height: auto;
    padding: 20px;
    
    @media ${device.tablet} {
        width: 15%;
        padding:0px;
    }
    
    @media ${device.laptop} {
        width: 14%;
        height: 250px;
    }
`

export const Image = styled.img`
    
    width: 100%;
    max-width:100%;
    @media ${device.laptop} {
        max-width: 80%;
        margin-bottom: 10px;
    }
`

export const Title = styled.h1`
    color: #FAC22A;
    font-weight: 700;
    letter-spacing: 0px;
    font-size: 2.6em;
    margin-bottom: 0px;
    line-height: normal;
`

export const BodyText = styled.p`
    color: #fff;
    margin-bottom: 0px;
    line-height: normal;
    margin-top: 10px;
    justify-self: flex-end;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1em;

    @media ${device.tablet} {
        font-size: 0.8em;
    }
`