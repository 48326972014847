import React from "react";
import * as Styled from "./styled";
const Badge = (props) => {
    return (
        <Styled.Container>
            <Styled.Image src={props.imgSrc}/>
            <Styled.Title>{props.title}</Styled.Title>
            <Styled.BodyText>{props.content}</Styled.BodyText>
        </Styled.Container>
    )
}

export default Badge;