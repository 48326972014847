const size = {
    mobileS: '320px',
    mobileSMax: '479px',
    mobile: '480px',
    mobileMax: '599px',
    mobileL: '600px',
    mobileLMax: '767px',
    tablet: '768px',
    tabletMax: '959px',
    laptop: '960px',
    laptopMax: '1199px',
    desktop: '1200px'
}

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobile: `(min-width: ${size.mobile})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    desktop: `(min-width: ${size.desktop})`
}