import styled from "styled-components";
import { device } from "../../utils/device";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    
    @media ${device.laptop} {
        flex-wrap: no-wrap;
    }
`