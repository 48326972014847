import styled from "styled-components"
import { device } from "../../utils/device"

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    @media ${device.tablet} {
        flex-direction: ${props => props.direction ? props.direction : "row"};
        justify-content: ${props => props.justifyContent ? props.justifyContent : "center" };
        align-items: ${props => props.alignItems ? props.alignItems : "center"};
        width: ${props => props.width ? props.width : "100%"};
    }

`