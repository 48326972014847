import styled from "styled-components";
import Img from "gatsby-image";
import { device } from "../../utils/device";

export const ImageContainer = styled.div `
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    position: relative;
`

export const Image = styled.img `
    width: 100%;
    height: auto;
    object-fit:cover;
    min-height: 100%;
`

export const FluidImage = styled(Img)
`
    width: 100%;
    height: auto;
    object-fit:cover;
    min-height: 100%;
`

export const HeroContent = styled.div `
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%,0%);
    display:block;
    z-index: 10;
`

export const HeroOverlay = styled.img`
    
    display:flex;

    width:100px;
    height: 100px;

    @media ${device.mobile} {
        width:200px;
        height: 200px;
    }

    @media ${device.tablet} {
        width: 50vw;
        height: 32vh;
    }

    @media ${device.laptop} {
        width: 400px;
        height: 400px;
    }

`