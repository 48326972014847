import React from "react";
import * as Styled from "./styled";
import { graphql, useStaticQuery } from "gatsby";
import { ImageCover } from "../ImageCover/styled";
import Ola from "../../assets/svg/Ola.svg";
import Header from "../Header";


const FullHeroImage = (props) => {

    const headerContent = (
        <Styled.HeroContent>
            <Styled.HeroOverlay src={Ola}/>
        </Styled.HeroContent>
    )

    const data = useStaticQuery(graphql`
        query headerImage {
            headerImage: file(relativePath: { eq: "MainImage.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2400) {
                    ...GatsbyImageSharpFluid
                },
            }
        }
        }
    `)

    return (
        <Styled.ImageContainer>
            <ImageCover coverColour="#000" opacity={0.25}/>
            <Header transparentBG={props.transparentBG}/>
            {headerContent}
            <Styled.FluidImage fadeIn={true} fluid={data.headerImage.childImageSharp.fluid} />
        </Styled.ImageContainer>
    )
}

export default FullHeroImage;