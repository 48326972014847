import React from "react"
import styled from "styled-components"

const FullWidthContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1400px;
  position: relative;
  margin: auto;
`

export default ({ children }) => ( <FullWidthContainer> { children } </FullWidthContainer>)