import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 60px 40px;
    background-color: ${props => props.bgColour};
    width: 100%;
`

export const MaxWidthContainer = styled.div`
    max-width: ${props => props.maxWidth ? props.maxWidth : "none"};
`

export const Title = styled.h1`
    color: ${props => props.colour};
    text-align: center;
`

export const underline = styled.hr`
    width: 20%;
    height: 1px;
    background-color: ${props => props.bgColour};
`