import React from "react";
import * as Styled from "./styled";

const ContentBlock = (props) => {
    return (
        <Styled.Container bgColour={props.bgColour ? props.bgColour : "#fff"}>
            {props.title && (
                <>
                    <Styled.Title colour={props.bgColour ? "#fff" : "#00444B"}>{props.title}</Styled.Title>
                    <Styled.underline bgColour={props.bgColour ? "#F2BD47" : "#F46149"}/>
                </>
            )
            }
            {props.children}
        </Styled.Container>
    )
}

export default ContentBlock;