import React from "react"
import * as Styled from "./styled"
import Logo from "../../assets/svg/Logo.svg";

const Header = (props) => {

    return (
        <Styled.Container transparentBG={props.transparentBG}>
            <Styled.Logo src={Logo}/>
        </Styled.Container>
    )
}


export default Header;