import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
`

export const Title = styled.h2`
    color: #DA4500;
    margin-bottom: 10px;
`

export const SubTitle = styled.p`
    color: #00A8A9;
    font-weight: bold;
    margin-bottom: 4px;
`

export const ChildContent = styled.div`
    p {
        margin-bottom: 20px;
    }
`

