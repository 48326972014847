import React from "react"
import * as Styled from "./styled"


const DetailTextBlock = (props) => {
    return (
        <Styled.Container>
            {props.title && <Styled.Title>{props.title}</Styled.Title>}
            {props.subtitle && <Styled.SubTitle>{props.subtitle}</Styled.SubTitle>}
            <Styled.ChildContent>{props.children}</Styled.ChildContent>
        </Styled.Container>
    )
}

export default DetailTextBlock;