import React from "react"
import BackgroundSlider from "gatsby-image-background-slider"
import { useStaticQuery, graphql } from "gatsby"
import * as Styled from "./styled"

const Slider = React.forwardRef((props, ref) => {
    return ( <Styled.SliderContainer ref={ref}>
        <BackgroundSlider query = { useStaticQuery(graphql `
            query{
                backgrounds: allFile (filter: {sourceInstanceName: {eq: "images"}}) {
                    nodes {
                        relativePath
                        childImageSharp {
                            fluid (maxWidth:1400, quality: 100){
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        `) }
        duration = { 15 }
        transition = { 3 }
        initDelay = { 3 }
        images = {
            ["Gallery1.jpg", "Gallery2.jpg", "Gallery3.jpg", "Gallery4.jpg", "Gallery5.jpg", "Gallery6.jpg", "Gallery7.jpg", "Gallery8.jpg", "Gallery9.jpg", "Gallery10.jpg"]
        }
        style = {
            {
                width: "100%",
                height: "788px",
                backgroundPosition: "top"
            }
        } >
        </BackgroundSlider>
        </Styled.SliderContainer >
    )
})

export default Slider