import styled from "styled-components";
import { device } from "../../utils/device"; 

export const PrimaryButton = styled.a`
    width: 80%;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 0.8em;
    text-align: center;
    margin: 20px 0px;
    transition: background-color 0.3s, color 0.3s;
    
    border: 1px solid #00A8A9;
    background-color: #00A8A9;
    color: white;
    &:hover {
        background-color: white;
        color: #00A8A9;
    }

    @media ${device.laptop} {
        width: 40%;
    }
`

export const SecondaryButton = styled(PrimaryButton)`
    background-color: #DA4500;
    border: 1px solid #DA4500;
    color: #F2BD47;
    &:hover {
        border: 1px solid #F2BD47;
        background-color: #F2BD47;
        color: #DA4500;
    }

`