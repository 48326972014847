import React from "react"
import Badge from "../../components/Badge"
import Icon_Sunglasses from "../../assets/svg/SunglassesIcon.svg"
import Icon_Cocktail from "../../assets/svg/DrinkIcon.svg"
import Icon_Passport from "../../assets/svg/PassportIcon.svg"
import Icon_Location from "../../assets/svg/LocationIcon.svg"
import Icon_Sun from "../../assets/svg/SunIcon.svg"
import * as Styled from "./styled"

const BadgesContainer = () => {
    return (
        <Styled.Container>
            <Badge imgSrc={Icon_Sunglasses} title="2" content="Bedrooms"/>
            <Badge imgSrc={Icon_Cocktail} title="Pool" content="Private pool and gardens"/>
            <Badge imgSrc={Icon_Passport} title="40" content="Minutes from airport"/>
            <Badge imgSrc={Icon_Location} title="15" content="Minutes to beach"/>
            <Badge imgSrc={Icon_Sun} title="Local" content="Restaurants, bars and golf club"/>
        </Styled.Container>
    )
}

export default BadgesContainer;