import React from "react"
import * as Styled from "./styled"

const Button = (props) => {
   
   switch(props.variant){
       case "primary":
        return <Styled.PrimaryButton href={props.linkURL}>{props.children}</Styled.PrimaryButton>;
       case "secondary":
        return <Styled.SecondaryButton href={props.linkURL}>{props.children}</Styled.SecondaryButton>
       default:
          return <Styled.PrimaryButton href={props.linkURL}>{props.children}</Styled.PrimaryButton>;

   }
}

export default Button