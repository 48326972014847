import styled from "styled-components"
import { device } from "../../utils/device"


export const Container = styled.div`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px 40px;
    top: 0px;
    left: 0px;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    z-index: 15;
    transition: all 0.4s;
    background-color: ${props => props.transparentBG ? 'rgba(0,168,169,0.8)' : 'rgba(0,168,169,0.3)'};

    // background-color: ${props => props.transparentBG ? 'rgba(152,211,219,0.8)' : 'rgba(152,211,219,0.3)'};
    
    box-shadow: ${props => props.transparentBG ? '0px 5px 5px 0px rgba(0,0,0,0.2)' : 'none'};
    
    @media ${device.tablet} {
        top: 0px;
        left: 0px;
        justify-content: flex-start;
        align-items: flex-start;
        transform: unset;
    }

`

export const Logo = styled.img `
    width: 200px;

`