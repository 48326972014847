import React, { useState } from "react"
import Layout from "../components/layout-default"
import FullHeroImage from "../components/FullHeroImage"
import Slider from "../components/Slider"
import { FlexContainer } from "../components/FlexContainer/styled"
import ContentBlock from "../components/ContentBlock/ContentBlock"
import Button from "../components/Button"
import MapImage from "../assets/img/Map.jpg";
import BadgesContainer from "../components/BadgesContainer"
import { device } from "../utils/device"
import DetailTextBlock from "../components/DetailTextBlock/DetailTextBlock"

export default () => {
  const [isScrolled, setisScrolled] = useState(false);
  if(typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')


    let last_known_scroll_position = 0;
    let ticking = false;

    window.addEventListener("scroll", (e) => {
      last_known_scroll_position = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(function() {
          checkScrollPosition(last_known_scroll_position);
          ticking = false;
        });

        ticking = true;
      }
    });
  }
  const checkScrollPosition = (currentScrollPos) => {
    setisScrolled(currentScrollPos > 0 ? true : false);
  }
  return (<Layout>
    <FullHeroImage transparentBG={isScrolled}/>
      <FlexContainer direction="column" justifyContent="center" alignContent="center">
        <ContentBlock title="Welcome to our villa">
          <p>Casa Gwendolina is our lovely Fortuna style villa, located in the popular Camposol in beautiful Mazarron.<br/>
          The villa is just a short drive from the main commercial square where you can find an abundance of shops, bars and restaurants of varying cuisines.<br/>
          Very close to the villa is the 18-hole Sensol Golf Course which also has a lovely restaurant and bar open to the public.</p>
          <Button linkURL={"/#gallery"} >Take a look around</Button>
        </ContentBlock>
        <ContentBlock bgColour="#F46149">
          <BadgesContainer/>
        </ContentBlock>
        <section id="gallery">
        <ContentBlock title="Villa and local area">
        </ContentBlock>
        <Slider />
        </section>
        <ContentBlock title="Local attractions">
          <p>Camposol is situated on the Costa Calida coast just south of the Costa Blanca.</p>
        </ContentBlock>
        <FlexContainer>
          <img alt="Map of the surrounding area" src={MapImage}/>
        </FlexContainer>
        <ContentBlock title="Villa details">
          <FlexContainer direction="row" justifyContent="space-around" alignContent="flex-start">
            <FlexContainer direction="column" justifyContent="center" alignContent="center" width={device.laptop ? "50%": "100%"}>
              <DetailTextBlock>
                <p>Casa Gwendolina is a lovely detached Fortuna style villa located on the popular urbanisation of Camposol in Mazarron. The villa is just a short drive from the main commercial square where you can find an abundance of shops, bars and restaurants of varying cuisines. Very close to the villa is the 18 hole Sensol Golf Course which also has a lovely restaurant and bar open to the public.</p>
                <p>At the front of the villa there is a large terrace with table &amp; chairs and a sun awning providing shade across half of the terrace. The views from here are of the surrounding mountains and open land.</p>
                <p>From the terrace there is a door leaning to the open plan, air conditioned living area which consists of a spacious lounge with corner suite and 2 armchairs. There is WiFi available for a fee of 8€ per week and UK TV via the internet. To the rear there is a dining table and the bright, well equipped kitchen.</p>
                <p>There are 2 bedrooms, both with fitted wardrobes and ceiling fans. The front master bedroom has a double bed, and the rear bedroom has twin single beds. The family bathroom is spacious with WC, basin and full bath with overhead shower. All windows and doors throughout the villa have flyscreens allowing you to leave windows open for a nice breeze, without worrying about unwanted flies and mosquitoes.</p>
                <p>Outside, at the rear of the villa is the private pool with Roman steps surrounded by Mediterranean trees. There is a further table and chairs underneath a wooden pergola and 4 loungers to enjoy the Spanish sun. There is also secure off road parking.</p>
                <p>The property can be rented on a longer term basis over the winter months. <br/> <strong>Please enquire for availabilty and prices.</strong></p>
              </DetailTextBlock>
            </FlexContainer>
            <FlexContainer direction="column" justifyContent="center" alignContent="center" width={device.laptop ? "50%": "100%"}>
            <DetailTextBlock title="Airport">
              <p>Airport access is fantastic as Murcia International RMU airport is only 40 minutes drive while Alicante airport with a huge choice of flights all over Europe is just over an hour drive away to the north. Taxi, shuttle bus hire and car rental all available from these airports.</p>
            </DetailTextBlock>
            <DetailTextBlock title="Explore">
              <p>The nearest town to Camposol is Mazarron, which is a charming unspoilt Spanish town 12 km away towards the coast. For beaches and the marina you would drive to Puerto Mazarron which is only 3 kilometres away from Mazarron town.</p>
            </DetailTextBlock>
            <DetailTextBlock subtitle="Costa Calida Beaches">
              <p>The Costa Calida, which, literally translates as 'Warm Coast', offers many blue-flag beaches that range from the very warm waters of the Mar Menor to the Mediterranean beaches scattered along the coast.</p>
            </DetailTextBlock>
            <DetailTextBlock subtitle="Murcia">
              <p>Murcia is a must-visit. Full of colourful, lively little plazas that nestle in-between the narrow streets. Murcia has an overwhelming array of museums, art galleries and centres and beautiful historic buildings, so if it is culture that you crave you will certainly enjoy your time here.</p>
            </DetailTextBlock>
            </FlexContainer>
          </FlexContainer>
        </ContentBlock>
        <ContentBlock bgColour="#F46149">
        <Button variant="secondary" linkURL={"https://www.holidaylettings.co.uk/rentals/camposol,-mazarron/11865138"} >Book here</Button>
        </ContentBlock>
      </FlexContainer>
  </Layout>)
}
